<template>
  <div class="selectbox">
    <div class="searchbox" v-if="0">
      <div class="serchinput">
        <div class="searchicon">
          <img src="@/assets/img/searchicon.png" alt="" />
        </div>
        <form action="">
          <input type="search" v-model="searchtext" />
        </form>
      </div>
      <button class="subsearch" @click="searchclick" v-if="0">搜索</button>
    </div>

    <div class="contant">
      <template v-for="(item, index) in search" :key="index" >
        <template  v-if="item.type=='date'">
          <dateBox :field="item" :name="index" :defaultData="searchData[index]" :setData="setData" />
            <!-- <dataselect :name="index" :showsigle="true" :select="searchData[index]" @singledata="setData" /> -->
        </template>

        <template  v-if="item.type=='dateDays'">
            <div class="title">{{item.text}}</div>
            <dataselect :name="index" :showmutiple="true" @multipledate="setData"/>
        </template>

        <template v-if="item.type=='radio' || item.type=='select'">
          <selectBox :field="item" :name="index" :returnType="item.return_type ? item.return_type : 'id'" :defaultData="searchData[index]" :setData="setData" />
        </template>

        <template v-if="item.type=='checkbox'">
          <div class="title">{{item.text}} <span>(多选)</span></div>
          <CheckboxBox :checkArray="item.value" :name="index" :select="searchData[index]" :setData="setData"/>
        </template>

        <template v-if="item.type=='area_select'">
          <!-- 地区选择 -->
          <div class="title">地区选择</div>
          <van-field
              v-model="areaValue"
              is-link
              readonly
              label="地区"
              placeholder="请选择所在地区"
              @click="areashow = true"
              class="areabox"
          />
          <van-popup v-model:show="areashow" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                title="请选择所在地区"
                :options="areaoptions"
                active-color="#2a57fa"
                @close="areashow = false"
                @finish="onFinish"
            />
          </van-popup>
        </template>

        <template v-if="item.type=='input'">
          <van-cell-group inset>
          <van-field v-model="retData[index]" :name="index" :label="item.text" :placeholder="item.place?item.place:''" />
          </van-cell-group>
        </template>

      </template>
      </div>

      <div class="title" v-if="false">提现</div>
      <CashOut v-if="false" />

      <div class="title" v-if="false">底部弹窗</div>
      <div class="open_popup" v-if="false">
        <div class="item" @click="OpenTextPopup">打开文档弹窗</div>
        <div class="item" @click="OpenCouponPopup">打开优惠券弹窗</div>
        <div class="item" @click="OpenPaymentPopup">打开银行卡弹窗</div>
        <div class="item" @click="toRoutPayMentPage">立即提现</div>
      </div>
      <TextPopup v-if="false"
        :title="TectPopupDeta.title"
        :contant="TectPopupDeta.contant"
        ref="TextPopupShow"
      />

      <CouponPopup v-if="false"
        ref="CouponPopupShow"
      />

      <PaymentPopup v-if="false"
        ref="PaymentPopupShow"
      />

    <div class="btns">
      <button type="button" class="sub" @click="subclick">确认</button>
      <button type="button" class="cancer" @click="cancerclick">取消</button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, toRefs } from "vue";
//import { Toast } from "vant";
import { useRouter } from "vue-router";
import CheckboxBox from "@/components/Checkbox";
//import Radiobox from "@/components/Radiobox";
import dataselect from "@/components/dataselect";
import CashOut from "@/components/CashOut";
import TextPopup from "@/components/TextPopup";
import CouponPopup from "@/components/CouponPopup";
import PaymentPopup from "@/components/PaymentPopup";
import selectBox from "@/resources/forms/SelectBox";
import dateBox from "@/resources/forms/DateBox";

const router = useRouter();
const searchtext = ref("");

function searchclick() {
  // console.log(searchtext.value);
}

// 调用父组件方法
const props = defineProps({
  searchfun: {
    type: Function,
    default: Function,
    required: true,
  },
  search: {
    type: Object,
    required: true
  },
  searchData: {
    type: Object,
    required: true
  },
});

const retData = ref({});
// 按钮
function subclick() {
  props.searchfun(searchData.value, 1);
}

function cancerclick() {
  searchtext.value = "";
  // 调用父组件方法
  props.searchfun();
}

function setData(name, value){
  //如果是未选择直接关闭弹窗则只需要改变显示状态
  if(value == 'overlay'){
    return false;
  }
  searchData.value[name] = value;
}
onMounted(() => {
  retData.value = searchData.value;
});
const { searchData } = toRefs(props);

// 底部弹窗
const TextPopupShow = ref(null);
const CouponPopupShow = ref(null);
const PaymentPopupShow = ref(null);

const OpenTextPopup = () => {
  TextPopupShow.value.OpenClick();
};

const OpenCouponPopup = () => {
  CouponPopupShow.value.OpenClick();
};

const OpenPaymentPopup = () => {
  PaymentPopupShow.value.OpenClick();
};

const TectPopupDeta = ref({
  title: "文档标题",
  contant:
    "这位墨西哥名导不仅是各种传说和神话方面的“专业大佬”，更是擅于将各种怪异元素组合成惊悚/恐怖片的名导。",
});

function toRoutPayMentPage() {
  router.push({
    name: "PaymentPage",
  });
}

// 地区选择
const areashow = ref(false);
const areaValue = ref("");
const cascaderValue = ref("");
// 选项列表，children 代表子选项，支持多级嵌套
const areaoptions = [
  {
    text: "浙江省",
    value: "330000",
    children: [
      {
        text: "杭州市",
        value: "330100",
        children: [
          {
            text: "上城区",
            value: "330110",
          },
        ],
      },
    ],
  },
  {
    text: "江苏省",
    value: "320000",
    children: [{ text: "南京市", value: "320100" }],
  },
];
// 全部选项选择完毕后，会触发 finish 事件
const onFinish = ({ selectedOptions }) => {
  areashow.value = false;
  areaValue.value = selectedOptions.map((option) => option.text).join("/");
};
</script>

<style lang="less" scoped>
.selectbox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-flow: column;
  padding-top: 20px;
  z-index: 20;
}

.searchbox {
  display: flex;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 50px;
  .serchinput {
    border-radius: 30px;
    height: 60px;
    flex: 1;
    margin-right: 20px;
    background: #efefef;
    padding: 0 10px 0 30px;
    box-sizing: border-box;
    display: flex;
    min-width: 0;
    align-items: center;
    .searchicon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    form {
      display: block;
      height: 100%;
      min-width: 0;
      flex: 1;
    }
    input {
      display: block;
      height: 100%;
      width: 100%;
      background: none;
      outline: none;
      color: @font-secondary-color;
      font-size: @font-size;
    }
    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      position: relative;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: url("../assets/img/searchclear.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .subsearch {
    display: block;
    background: none;
    color: @font-secondary-color;
    font-size: @font-size;
  }
}

.contant {
  flex-grow: 1;
  min-height: 10px;
  overflow-y: auto;
  padding: 20px;
}

.title {
  color: @font-secondary-color;
  font-size: @subtitle-size;
  line-height: @subtitle-size;
  position: relative;
  font-weight: bolder;
  font-family: "Regular";
  margin-bottom: 30px;
  padding-top: 30px;
}

// 开关css
.switchbox {
  .switch-off {
    width: 66px;
    height: 36px;
    border-radius: 18px;
    background: #efefef;
    display: block;
    border: 2px solid #e2e2e2;
    position: relative;
    transition: 0.2s linear;
    .slider {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      right: auto;
      background: #fff;
      transition: 0.2s linear;
    }
  }
  .switch-on {
    width: 66px;
    height: 36px;
    border-radius: 18px;
    background: #6d9eeb;
    display: block;
    border: 2px solid #6d9eeb;
    position: relative;
    .slider {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: block;
      position: absolute;
      background: #fff;
      top: 1px;
      left: 32px;
    }
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .sub {
    width: 30%;
    height: 80px;
    outline: none;
    background: @theme-secondary-color;
    color: #fff;
    font-size: @font-size;
    font-family: "Bold";
    border-radius: 49px;
    margin-right: 20px;
  }
  .cancer {
    width: 30%;
    height: 80px;
    outline: none;
    background: @font-third-color;
    color: #fff;
    font-size: @font-size;
    font-family: "Bold";
    border-radius: 49px;
  }
}

.open_popup {
  display: flex;
  flex-flow: wrap;
  .item {
    font-size: @font-size;
    line-height: @font-size;
    color: @font-secondary-color;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    padding: 15px;
    background: #efefef;
    width: auto;
    margin-right: 20px;
  }
}
</style>

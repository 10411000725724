<template>
  <div class="formitem">
    <van-field
      v-model="selectText"
      is-link
      readonly
      :name="name"
      :label="field.text?field.text:'请选择'"
      :placeholder="field.place?field.place:'请选择'"
      @click="showPicker = true"
      v-if="fieldShow"
    />
    <van-popup v-model:show="showPicker" position="bottom"  @click-overlay="onClickOverlay">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        :title="field.text?field.text:'请选择'"
        @cancel="onCancel"
        :default-index="defaultIndex"
      />
    </van-popup>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, toRefs, watch} from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  defaultData: {
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  returnType:{
    type:String,
    required:false,
    default:'id',//id、text、both
  },
  fieldShow:{
    type:Boolean,
    required: false,
    default:true
  },
  boxShow:{
    type:Boolean,
    required: false,
    default:false
  },
});

const { field, name, returnType,defaultData,boxShow } = toRefs(props);
const columns = [];
const result = ref('');
const selectText = ref("");
const defaultIndex = ref(0);
const showPicker = ref(false);
let selectItems = {};

onMounted(()=>{
  //配置
  selectItems = field.value.value;
  //默认值
  if((defaultData.value == '')
      || (defaultData.value == undefined)
      || (typeof(selectItems[defaultData.value]) =='undefined')
  ){
    result.value = '';
  }else{
    if(returnType.value == 'id'){
      selectText.value =selectItems[defaultData.value];
      result.value = defaultData.value;
    }else if(returnType.value == 'both'){
      selectText.value =selectItems[defaultData.value];
      result.value = {'text':selectItems[defaultData.value],'value':defaultData.value};
    }else {
      result.value = selectText.value = selectItems[defaultData.value]
    }
  }
  //转成组件需要的的数据格式
  let index = 0;
  for(let i in selectItems){
    columns.push({text:selectItems[i], value:i});
    if(defaultData.value == i){
      defaultIndex.value = index;
    }
    ++index;
  }
});

function onConfirm(item){
  if(returnType.value == 'id'){
    result.value = item.value;
  }else if(returnType.value == 'both'){
    // result.value = {'text':item.text,'value':item.value};
    result.value = item;
  }else {
    result.value = item.text;
  }
  selectText.value = item.text;
  props.setData(name.value, result.value);
  showPicker.value = false;
}
const onCancel = () => {
  showPicker.value = false;
  props.setData(name.value, 'overlay');
};
const onClickOverlay = () => {
  showPicker.value = false;
  props.setData(name.value, 'overlay');
};
///监听控制点击时展示地区弹窗
watch(boxShow, (newData) => {
  if (newData) {
    showPicker.value = true;
  } else {
    showPicker.value = false;
  }
});
</script>

<style lang="less" scoped>
.formitem {
  margin-bottom: 30px;
}
</style>

import axios from 'axios'

const VIEW = axios.create({
	baseUrl:'/api', //请求后端数据的基本地址，自定义
	timeout: 10000                   //请求超时设置，单位ms
})
VIEW.interceptors.request.use(
  config => {
    config.headers = { 'X-Requested-With': 'Vue' }
    return config
  }
)
VIEW.interceptors.response.use(function (res) {
	if(res.data.status == 100){
		window.location.href = '/saas/login';
	}
    return res;
});
export default VIEW
<template>
  <div class="PaymentPopup">
    <van-popup v-model:show="PopupShow" round style="height: 80vh" position="bottom">
      <div class="m_paymentpopup">
        <div class="contant">
          <div class="payment_list">
            <div class="item" v-for="(item, index) in paymentlist" :key="index">
              <div class="icon">
                <img :src="item.icon" alt="" />
              </div>
              <div class="info">
                <div class="name">{{ item.name }}</div>
                <div class="card_id">{{ item.cardId }}</div>
              </div>
              <div class="item_btns">
                <button type="button" class="edit_item" @click="EditClick(item)">
                  编辑
                </button>
                <button type="button" class="dele_item" @click="DeleClick">移除</button>
              </div>
            </div>
            <div class="add_item" @click="ShowInputBank">
              <div class="icon">
                <img src="@/assets/img/add_icon.png" alt="" />
              </div>
              <div class="text">添加银行卡</div>
            </div>
          </div>
        </div>
        <div class="btns">
          <button type="button" class="sub" @click="CancerClick">关闭</button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="InputBankShow" round style="width: 80vw">
      <div class="input_bank_card">
        <div class="title">{{ InputBankTitle }}</div>
        <div class="contant">
          <van-field
            v-model="BankName"
            name="归属银行"
            placeholder="选择银行"
            @click="SelectBankShow = true"
          />
          <van-field v-model="BankCardId" name="银行卡号" placeholder="输入银行卡号" />
        </div>
        <div class="btns">
          <button type="button" class="sub" @click="BankSubClick">确认</button>
          <button type="button" class="cancer" @click="BankCancerClick">关闭</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="SelectBankShow" round position="bottom">
      <van-picker
        title="选择银行"
        :columns="SelectBanDeta"
        @confirm="SelectBanOk"
        @cancel="SelectBankShow = false"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, defineExpose } from "vue";

const paymentlist = ref([
  {
    icon: require("@/assets/img/Payment_icon1.png"),
    name: "工商银行信用卡",
    cardId: "****1563",
  },
  {
    icon: require("@/assets/img/Payment_icon2.png"),
    name: "招商银行信用卡",
    cardId: "****1511",
  },
]);

const PopupShow = ref(false);

const OpenClick = () => {
  PopupShow.value = true;
};

const CancerClick = () => {
  PopupShow.value = false;
};

// 添加银行卡
const InputBankTitle = ref("");
const InputBankShow = ref(false);
const BankName = ref("");
const BankCardId = ref("");
const InputBankPopupStatus = ref(null);

const ShowInputBank = () => {
  InputBankShow.value = true;
  InputBankTitle.value = "添加银行卡";
  InputBankPopupStatus.value = 1;
};

const BankSubClick = () => {
  if (InputBankPopupStatus.value == 1) {
    // 提交添加
  } else if (InputBankPopupStatus.value == 2) {
    // 提交编辑
  }
};

const BankCancerClick = () => {
  InputBankShow.value = false;
  BankName.value = "";
  BankCardId.value = "";
};

// 选择银行
const SelectBankShow = ref(false);

const SelectBanDeta = [
  "中国工商银行",
  "中国建设银行",
  "中国农业银行",
  "中国银行",
  "招商银行",
  "富国银行",
  "大通银行",
];

const SelectBanOk = (value) => {
  BankName.value = value;
  SelectBankShow.value = false;
};

const EditClick = (item) => {
  InputBankShow.value = true;
  InputBankTitle.value = "编辑银行卡";
  InputBankPopupStatus.value = 2;
  BankName.value = item.name;
  BankCardId.value = item.cardId;
};

const DeleClick = () => {
  // 删除银行卡;
};

defineExpose({ OpenClick });
</script>

<style lang="less" scoped>
.m_paymentpopup {
  display: flex;
  flex-flow: column;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: #f7f8fa;
  .contant {
    flex-grow: 1;
    height: 10px;
    overflow-y: auto;
    margin-bottom: 30px;
    .payment_list {
      padding: 30px;
      .item {
        background: #fff;
        display: flex;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 0 8px #e2e2e2;
        position: relative;
        overflow: hidden;
        align-items: center;
        .icon {
          width: 100px;
          margin-right: 20px;
          padding: 20px 0 20px 20px;
          img {
            display: block;
            width: 100%;
          }
        }
        .info {
          flex: 1;
          min-width: 0;
          height: 160px;
          box-sizing: border-box;
          background: #fff;
          color: #000;
          display: flex;
          flex-flow: column;
          justify-content: center;
          .name {
            font-size: @font-size;
            color: @font-color;
          }
          .card_id {
            display: flex;
            justify-content: space-between;
            color: @font-third-color;
            font-size: @font-size;
          }
        }
        .item_btns {
          display: flex;
          height: 160px;
          button {
            width: 90px;
            height: 160px;
            font-size: @font-size;
            color: @font-white-color;
            display: block;
          }
          .edit_item {
            background: @font-hot-color;
          }
          .dele_item {
            background: #ccc;
          }
        }
      }

      .add_item {
        background: #fff;
        display: flex;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 0 8px #e2e2e2;
        position: relative;
        overflow: hidden;
        padding: 20px;
        align-items: center;
        .icon {
          width: 60px;
          padding: 0 20px;
          margin-right: 20px;
          img {
            display: block;
            width: 100%;
          }
        }
        .text {
          font-size: @font-size;
          color: @font-color;
        }
      }
    }
  }
}

.input_bank_card {
  padding: 30px;
  .title {
    text-align: center;
    color: @font-color;
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .contant {
    margin-bottom: 30px;
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .sub {
    width: 30%;
    height: 80px;
    outline: none;
    background: @theme-secondary-color;
    color: #fff;
    font-size: @font-size;
    font-family: "Bold";
    border-radius: 49px;
    margin-right: 20px;
  }
  .cancer {
    width: 30%;
    height: 80px;
    outline: none;
    background: @font-third-color;
    color: #fff;
    font-size: @font-size;
    font-family: "Bold";
    border-radius: 49px;
  }
}
</style>

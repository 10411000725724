<template>
  <div class="CashOut">
    <div class="title">
      <div class="icon"><img src="@/assets/img/rmb_icon1.png" alt="" /></div>
      <div class="text">可提现金额</div>
      <div class="Withvalue">{{data.withdraw_amount}}</div>
    </div>
    <div class="bottomcont">
      <div class="coldvalue">
        <div class="subtit">
          <div class="icon"><img src="@/assets/img/rmb_icon2.png" alt="" /></div>
          <div class="text">冻结金额</div>
        </div>
        <div class="value">{{data.froze_amount}}</div>
      </div>
      <div class="btns">
        <button type="button" @click="applyWithdraw">
          <div class="icon"><img src="@/assets/img/icon1.png" alt="" /></div>
          <div class="text">立即提现</div>
        </button>
        <button type="button" @click="bankInfo">
          <div class="icon"><img src="@/assets/img/icon2.png" alt="" /></div>
          <div class="text">银行卡信息</div>
        </button>
      </div>
    </div>
    <PaymentPopup ref="PaymentPopupShow" />
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps, ref } from "vue";
import PaymentPopup from "@/components/PaymentPopup";
import { useRouter } from "vue-router";
import mitts from "@/bus";
import API from "@/axios";
const props = defineProps({
  field: {
    type: Object,
    required: true,
  }
});
const router = useRouter();
const data = ref({});

function bankInfo(){
  mitts.emit("showLoading");
  router.push({
    name: 'event',
    query: {url:encodeURIComponent('/web/bankList')}
  });
}
function applyWithdraw(){
  mitts.emit("showLoading");
  router.push({
    name: 'event',
    query: {url:encodeURIComponent('/web/applyWithdraw')}
  });
}

function getData() {
  API({
    url: field.value.url,
    method: "post",
  }).then((res) => {
    data.value = res.data.data;
  });
}
// const PaymentPopupShow = ref(null);
// const OpenPaymentPopup = () => {
//   PaymentPopupShow.value.OpenClick();
// };

onMounted(() => {
  if(field.value){
    getData();
  }
});
const { field } = toRefs(props);
</script>

<style lang="less" scoped>
.CashOut {
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
  //width: 100%;
  margin: 0px 30px 10px;
}
.title {
  display: flex;
  align-items: center;
  .icon {
    width: 38px;
    height: 38px;
    margin-right: 5px;
    img {
      display: block;
      width: 100%;
    }
  }
  .text {
    font-size: @subtitle-size;
    color: @font-secondary-color;
    line-height: @subtitle-size;
  }
}

.Withvalue {
  text-align: center;
  font-size: @title-size;
  color: @font-color;
  line-height: @title-size;
  font-weight: bold;
  padding: 30px 50px;
}

.bottomcont {
  display: flex;
  .coldvalue {
    flex: 1;
    min-width: 0;
    .subtit {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .icon {
        width: 38px;
        height: 38px;
        margin-right: 5px;
        img {
          display: block;
          width: 100%;
        }
      }
      .text {
        font-size: @fontmini-size;
        color: @font-third-color;
        line-height: @fontmini-size;
      }
    }
    .value {
      font-size: @fontmini-size;
      color: @font-secondary-color;
      line-height: @fontmini-size;
      width: 100%;
      margin-left: 43px;
    }
  }
  .btns {
    display: flex;
    button {
      display: flex;
      margin-left: 15px;
      background: none;
      border-radius: 10px;
      border: 2px solid #ffba68;
      padding: 15px;
      align-items: center;
      .icon {
        width: 38px;
        height: 38px;
        margin-right: 10px;
        img {
          display: block;
          width: 100%;
        }
      }
      .text {
        font-size: @subtitle-size;
        color: #ffba68;
        line-height: @subtitle-size;
      }
    }
  }
}
</style>

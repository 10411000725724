<template>
  <div class="formitem">
    <van-field
      v-model="result"
      is-link
      readonly
      :name="name"
      :label="field.text?field.text:'所属日期'"
      :placeholder="field.place?field.place:'请选择日期'"
      @click="showCalendar = true"
      v-if="fieldShow"
    />
    <van-calendar
        v-model:show="showCalendar"
        @confirm="Calendardata"
        :type="rangeType"
        :default-date="defaultDate"
        :show-confirm="false"
        :min-date="minDate"
        :max-date="maxDate"
        :allow-same-day = "allowSameDay"
        color="#486ffb"
        @close="onClickOverlay"
    />

  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, toRefs, watch} from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  defaultData: {
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
  rangeType: {
    type: String,
    required: false,
    default:'single'
  },
  fieldShow:{
    type:Boolean,
    required: false,
    default:true
  },
  boxShow:{
    type:Boolean,
    required: false,
    default:false
  },
});
const { field, name,defaultData, rangeType,fieldShow,boxShow} = toRefs(props);

const result = ref('');
const defaultDate = ref('');
const showCalendar = ref(false);
const minDate = ref(new Date(2020, 0, 1));
const maxDate = ref(new Date());
const allowSameDay = ref(false)

onMounted(()=>{
  if(defaultData.value != '' && defaultData.value != undefined){
    if(rangeType.value == 'single'){
      //single的日期数据类型是字符串
      //Date支持2021/07/14、2021,07,14、1626244866842这三种格式,不支持2021-07-14这种格式
      defaultDate.value = new Date(defaultData.value.replace(/-/g,  "/"));
    }else{
      //例如2023-03-09/2023-03-15
      //multiple、range的日期数据类型是数组
      let timeTempArr = defaultData.value.split('/');
      let defaultDateArr = [];
      for(let i in timeTempArr){
        defaultDateArr.push(new Date(timeTempArr[i].replace(/-/g,  "/")));
      }
      defaultDate.value =defaultDateArr;
    }
  }
  //日期范围时允许点击同一天
  if(rangeType.value == 'range'){
    allowSameDay.value = true;
  }
});

const formatDate = (dateStr) => `${dateStr.getFullYear()}/${dateStr.getMonth() + 1}/${dateStr.getDate()}`;

const Calendardata = (dates) => {
  showCalendar.value = false;
  if(rangeType.value == 'range'){//range区间
    const [start, end] = dates;
    result.value = `${formatDate(start)}~${formatDate(end)}`;
    props.setData(name.value, result.value);
  }else if(rangeType.value == 'multiple'){//multiple多选
    for (const [value0, value1] of dates) {
      console.log('多选日期', value0, value1);
    }
  }else{//single单选
    let day = dates.getDate();
    if(day<10){
      day = '0'+day;
    }
    let month = dates.getMonth() + 1;
    if(month<10){
      month = '0'+month;
    }
    result.value = `${dates.getFullYear()}-${month}-${day}`;
    props.setData(name.value, result.value);
  }
};

const onClickOverlay = () => {
  showCalendar.value = false;
  props.setData(name.value, 'overlay');
};
//监听控制父级页面点击时展示地区弹窗
watch(boxShow, (newData) => {
  if (newData) {
    showCalendar.value = true;
  } else {
    showCalendar.value = false;
  }
});
</script>

<style lang="less" scoped>
.formitem {
  margin-bottom: 30px;
}
</style>

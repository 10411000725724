<template>
  <div class="dataselect">
    <div class="selectcont">
      <div class="item" v-if="showsigle" @click="singleshow = true">{{ singledate }}</div>
      <div class="item" v-if="showmutiple" @click="multipleshow = true">
        {{ multipledate }}
      </div>
    </div>

    <van-calendar
      v-model:show="singleshow"
      @confirm="singledata"
      :show-confirm="false"
      :min-date="minDate"
      color="#1989fa"
    />

    <van-calendar
      v-model:show="multipleshow"
      type="range"
      @confirm="multipledata"
      :show-confirm="false"
      row-height="60"
      :min-date="minDate"
      :allow-same-day="true"
      color="#1989fa"
    />
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, defineEmits, onMounted } from "vue";

const props = defineProps({
  showsigle: {
    type: Boolean,
    required: true,
  },
  showmutiple: {
    type: Boolean,
    required: true,
  },
  select: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
});

// 日期选择
const singledate = ref("日期筛选");
const multipledate = ref("日期区间筛选");

const singleshow = ref(false);
const multipleshow = ref(false);

const minDate = ref(new Date(2010, 0, 1));
// 注册方法
const emit = defineEmits(["singledata","multipledate"]);

const singleformatDate = (singledate) =>{
  let day = singledate.getDate();
  if(day<10){
    day = '0'+day;
  }
  let month = singledate.getMonth() + 1;
  if(month<10){
    month = '0'+month;
  }
  return `${singledate.getFullYear()}-${month}-${day}`;
}

const singledata = (value) => {
  singleshow.value = false;
  singledate.value = singleformatDate(value);
  emit("singledata", name.value, singledate.value);
  multipledate.value = "日期区间筛选";
};

const multipleformatDate = (multipledate) =>{
  let day = multipledate.getDate();
  if(day<10){
    day = '0'+day;
  }
  let month = multipledate.getMonth() + 1;
  if(month<10){
    month = '0'+month;
  }
  return `${multipledate.getFullYear()}-${month}-${day}`;
}

const multipledata = (values) => {
  const [start, end] = values;
  multipleshow.value = false;
  multipledate.value = `${multipleformatDate(start)}至${multipleformatDate(end)}`;
  emit("multipledate", 'start_' + name.value, `${multipleformatDate(start)}`);
  emit("multipledate", 'end_' + name.value, `${multipleformatDate(end)}`);
  singledate.value = "日期筛选";
};

onMounted(() => {
  singledate.value = select.value?select.value:'日期筛选';
});


const { showsigle, showmutiple, select, name } = toRefs(props);
</script>

<style lang="less" scoped>
.selectcont {
  margin-bottom: 30px;
  display: flex;
  .item {
    font-size: @font-size;
    line-height: @font-size;
    color: @font-secondary-color;
    margin-right: 20px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    padding: 15px;
    background: #efefef;
  }
}
</style>

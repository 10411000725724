<template>
  <div class="Checkbox">
    <div class="title" v-if="title">{{title}}</div>
    <div
      class="item"
      v-for="(item, index) in checkArray"
      :class="checklist.indexOf(index) != -1 ? 'on' : ''"
      :key="index"
      @click="checkClick(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, onMounted } from "vue";

const props = defineProps({
  checkArray: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  select: {
    // type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  setData: {
    type: Function,
    default: Function,
    required: true,
  },
});

const checklist = ref([]);
const checktext = ref("");
onMounted(() => {
  if(typeof(select.value) == 'undefined' ||select.value == '' ){
    checklist.value = [];
  }else if(typeof(select.value) == 'object'){
    checklist.value = select.value;
  }else if(typeof(select.value) == 'string'){
    checklist.value = select.value.split(',');
  }
});

function checkClick(item) {
  if (checklist.value.indexOf(item) == -1) {
    checklist.value.push(item);
  } else {
    for (let i = 0; i < checklist.value.length; i++) {
      if (checklist.value[i] == item) {
        checklist.value.splice(i, 1);
      }
    }
  }

  checktext.value = checklist.value.join(",");

  props.setData(name.value, checktext.value);
}

const { checkArray,name,select } = toRefs(props);
</script>

<style lang="less" scoped>
.Checkbox {
  display: flex;
  flex-flow: wrap;
  .item {
    font-size: @font-size;
    line-height: @font-size;
    color: @font-secondary-color;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    padding: 15px;
    background: #efefef;
  }
  .on {
    color: @font-white-color;
    background: #6d8bf7;
    border: 1px solid #6d8bf7;
  }
}
.title{
  font-size: @font-size;
  line-height: @font-size;
  color: @font-secondary-color;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #00000000;
  padding: 15px;
}
</style>
